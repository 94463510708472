/* https://wdrfree.com/254/how-to-create-simple-css-grid-system */

.row:after {
    display: table;
    content: " ";
    clear: both;
  }
  
  .col1,
  .col2,
  .col3,
  .col4,
  .col5,
  .col6,
  .col7,
  .col8,
  .col9,
  .col10,
  .col11,
  .col12 {
    float: left;
  }
  
  .col1 {
    width: 8.333333333333334%;
  }
  
  .col2 {
    width: 16.666666666666668%;
  }
  
  .col3 {
    width: 25%;
  }
  
  .col4 {
    width: 33.333333333333336%;
  }
  
  .col5 {
    width: 41.66666666666667%;
  }
  
  .col6 {
    width: 50%;
  }
  
  .col7 {
    width: 58.333333333333336%;
  }
  
  .col8 {
    width: 66.66666666666667%;
  }
  
  .col9 {
    width: 75%;
  }
  
  .col10 {
    width: 83.33333333333334%;
  }
  
  .col11 {
    width: 91.66666666666667%;
  }
  
  .col12 {
    width: 100%;
  }
  
  /*@media (max-width: 768px) {
    .col1,
    .col2,
    .col3,
    .col4,
    .col5,
    .col6,
    .col7,
    .col8,
    .col9,
    .col10,
    .col11,
    .col12 {
      width: 100%;
    }
  }*/